import React from 'react';
import {withTranslation} from "react-i18next";
import {loadTextsByNamespace} from "../../../server/helper/ssrHelper";
import config from "../../config/main.config";
import EditHTMLComponent from "../../components/editHTML/EditHTMLComponent";

const OfferPage = () => {
    return (
        <div className={'grid ui'} style={{padding: '3rem'}}>
            <div className={'row centered'}>
                <div className={'fourteen wide column'}>
                    <EditHTMLComponent id={"OfferPage-text-1"}/>
                </div>

            </div>
        </div>


    )
}
OfferPage.fetchData = () => {
    return loadTextsByNamespace(config.BASE_URL, 'OfferPage').then((text) => {
        return text;
    })
}
export default withTranslation(['common'])(OfferPage);
